
import { computed, defineComponent, reactive, ref, watch, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  BookTwoTone,
  CheckCircleFilled,
  HistoryOutlined,
  MoneyCollectFilled,
  PlusOutlined,
  PropertySafetyOutlined,
  ScheduleOutlined,
  SoundOutlined,
} from '@ant-design/icons-vue';
import {
  check_adviser_info_num,
  check_payslip_bulk_progress,
  get_adviser_contract_tip,
  query_invoice_by_wechat_list,
} from '@/api/cerp-model';
import Radar from './components/radar/index.vue';
import { useStore } from 'vuex';
import { get_notice_record_no_read_num, query_notice_record_list } from '@/api/baseinfo-model';
import { useRoute, useRouter } from 'vue-router';
import { getDateTime, getMonth } from '@/utils/function';

export default defineComponent({
  setup() {
    const img_a = reactive({img:require('@/assets/user/jidu.svg')});
    const img_b = reactive({img:require('@/assets/user/gongzijindu.svg')});
    const img_c = reactive({img:require('@/assets/user/gongzijindu1.svg')});
    const img_d = reactive({img:require('@/assets/user/gongzijindu2.svg')});
    const img_e = reactive({img:require('@/assets/user/gongzijindu3.svg')});
    const img_f = reactive({img:require('@/assets/user/gongzijindu4.svg')});
    const img_g = reactive({img:require('@/assets/user/gongzijindu5.svg')});
    const img_h = reactive({img:require('@/assets/user/gongzijindu6.svg')});
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const path_arr = computed(() =>route.path.split('/'));

    const current_org = store.getters['user/current_org'];
    const adviser = computed(() => store.getters[`user/adviser`]);
    const no_read_num = ref(0);
    const is_alert = ref(false);
    const diff_day = ref(0);
    const adviser_info_num = ref(0);
    const cc_company = ref('');
    const advicer_no_read_number = computed(() => store.getters['user/no_read_number']);
    const current_company = computed(() => store.getters['user/current_company']);
    const service_month: any = ref(null);
    const pay_status: any = ref(null);
    const data: any = reactive({
      data: [],
      data1: [],
      current: 1,
      pageSize: 10,
      total: 0,
      current1: 1,
      pageSize1: 10,
      total1: 0,
    })
    const state:any = reactive({
      steps: [
        {
          desc: '',
        },
        {
          desc: '',
        },
        {
          desc: '',
        },
        {
          desc: '',
        },
        {
          desc: '',
        },
      ]
    })
    watch(() => current_company.value.id,
      () => {
        query_invoice_by_wechat_list({
          org_id: current_org.id,
          adviser_id: adviser.value.adviser_id,
          invoice_type: 1,
          cc_company_id: current_company.value.id,
          is_active: true,
        }).then((res: any) => {
          if (res && res.data && res.data.length > 0) {
            service_month.value = getMonth(res.data[0].service_month);
            cc_company.value = res.data[0].cc_company__name;
            check_payslip_bulk_progress({
              org_id: current_org.id,
              invoice_ids: [res.data[0].invoice_id],
            }).then((res1: any) => {
              const f = res1.find((temp: any) => temp.invoice_id === res.data[0].invoice_id);
              if (f) {
                state.steps[0].desc = f.pay_time ? f.pay_time : '';
                state.steps[1].desc = f.customer_pay_time ? f.customer_pay_time : '';
                state.steps[2].desc = f.confirm_time ? f.confirm_time : '';
                state.steps[3].desc = f.send_time ? f.send_time : '';
                state.steps[4].desc = f.create_time ? f.create_time : '';
                if (state.steps[4].desc) {
                  pay_status.value = t('pages.workplace.receipted_man_hour');
                }
                if (state.steps[3].desc) {
                  pay_status.value = t('pages.workplace.payment_request_submitted');
                }
                if (state.steps[2].desc) {
                  pay_status.value = t('pages.workplace.remittance_received');
                }
                if (state.steps[1].desc) {
                  pay_status.value = t('pages.workplace.receipted_payment');
                }
                if (state.steps[0].desc) {
                  pay_status.value = t('pages.workplace.paid');
                }
              }
            });
          } else {
            service_month.value = null;
            cc_company.value = '';
            pay_status.value = null;
            state.steps[0].desc = '';
            state.steps[1].desc = '';
            state.steps[2].desc = '';
            state.steps[3].desc = '';
            state.steps[4].desc = '';
          }
        });
      });
    const main = () => {
      get_notice_record_no_read_num({
        org_id: current_org.id,
        adviser_id: adviser.value.adviser_id,
      }).then((res: any) => {
        no_read_num.value = res.num;
      });
      check_adviser_info_num({
        org_id: current_org.id,
        adviser_id: adviser.value.adviser_id,
      }).then((res: any) => {
        adviser_info_num.value = res;
      })
      query_invoice_by_wechat_list({
        org_id: current_org.id,
        adviser_id: adviser.value.adviser_id,
        invoice_type: 1,
        cc_company_id: current_company.value.id,
        is_active: true,
      }).then((res: any) => {
        if(res && res.data && res.data.length > 0){
          service_month.value = getMonth(res.data[0].service_month);
          cc_company.value = res.data[0].cc_company__name;
          check_payslip_bulk_progress({
            org_id: current_org.id,
            invoice_ids: [res.data[0].invoice_id],
          }).then((res1: any) => {
            const f = res1.find((temp: any) => temp.invoice_id === res.data[0].invoice_id);
            if (f) {
              state.steps[0].desc = f.pay_time ? f.pay_time : '';
              state.steps[1].desc = f.customer_pay_time ? f.customer_pay_time : '';
              state.steps[2].desc = f.confirm_time ? f.confirm_time : '';
              state.steps[3].desc = f.send_time ? f.send_time : '';
              state.steps[4].desc = f.create_time ? f.create_time : '';
              if(state.steps[4].desc){
                pay_status.value = t('pages.workplace.receipted_man_hour');
              }
              if(state.steps[3].desc){
                pay_status.value = t('pages.workplace.payment_request_submitted');
              }
              if(state.steps[2].desc){
                pay_status.value = t('pages.workplace.remittance_received');
              }
              if(state.steps[1].desc){
                pay_status.value = t('pages.workplace.receipted_payment');
              }
              if(state.steps[0].desc){
                pay_status.value = t('pages.workplace.paid');
              }
            }
          })
        }
      })
      if(adviser.value.status == 3){
        get_adviser_contract_tip({
          org_id: current_org.id,
          adviser_id: adviser.value.adviser_id,
        }).then((res: any) => {
          if(res && res.diff_day){
            diff_day.value = res.diff_day;
            is_alert.value = true;
          }
        })
      }
    }

    const change = (page: any, pageSize: any) => {
      query_notice_record_list({
        org_id: current_org.id,
        adviser_id: adviser.value.adviser_id,
        notice_type: 0,
        is_active: true,
        current: page,
        pageSize: pageSize,
        sort_fields: ['is_read', '-notice__create_time'],
      }).then((res: any) => {
        data.current = res.current;
        data.pageSize = res.pageSize;
        data.total = res.total;
        if(res && res.data && res.data.length > 0){
          data.data.length = 0;
          res.data.map((item: any) => {
              data.data.push(item);
          })
        }
      })
    }
    const change1 = (page: any, pageSize: any) => {
      query_notice_record_list({
        org_id: current_org.id,
        adviser_id: adviser.value.adviser_id,
        notice_type: 1,
        is_active: true,
        current: page,
        pageSize: pageSize,
        sort_fields: ['is_read', '-sorted_index'],
      }).then((res: any) => {
        data.current1 = res.current;
        data.pageSize1 = res.pageSize;
        data.total1 = res.total;
        if(res && res.data && res.data.length > 0){
          data.data1.length = 0;
          res.data.map((item: any) => {
              data.data1.push(item);
          })
        }
      })
    }

    const look_notice = (record: any) => {
      if(!record.is_read && no_read_num.value > 0){
        no_read_num.value = no_read_num.value - 1;
      }
      record.is_read = true;
      router.push({
        path: `/static-info-manage/baseinfo-notice/${record.notice_id}/${record.id}/`,
        query: { customTitle: record.notice__title }
      });
    }
    watchEffect(() => {
      if (path_arr.value[path_arr.value.length - 1] == 'adviser_workplace') {
        main();
        change(1,10);
        change1(1,10);
      }
    });
    return {
      t,
      currentUser: computed(() => store.getters[`user/currentUser`]),
      data,
      img_a,
      img_b,
      img_c,
      img_d,
      img_e,
      img_f,
      img_g,
      img_h,
      state,
      adviser_info_num,
      no_read_num,
      advicer_no_read_number,
      service_month,
      pay_status,
      diff_day,
      is_alert,
      cc_company,
      getDateTime,
      look_notice,
      change,
      change1,
    };
  },
  components: {
    Radar,
    SoundOutlined,
    CheckCircleFilled,
    BookTwoTone,
    ScheduleOutlined,
    HistoryOutlined,
    MoneyCollectFilled,
    PropertySafetyOutlined,
    PlusOutlined,
  },
});
